var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card',{staticClass:"px-1",attrs:{"color":"surface","flat":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-flex',{attrs:{"shrink":""}},_vm._l((_vm.selectedFilters),function(item,index){return _c('v-menu',{key:index,attrs:{"close-on-content-click":false},on:{"input":function($event){$event || item.value ? null : _vm.cancelFilter(index, item.type)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"outlined":"","filter":"","close":""},on:{"click:close":function($event){return _vm.cancelFilter(index, item.type)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" "),(item.value)?_c('span',[_vm._v(": "+_vm._s(item.value))]):_vm._e()])]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[(item.type == 0)?_c('Textfield',{attrs:{"info":item,"filter_name":item.identifier},on:{"success":function($event){return _vm.setFilterValue($event, index)},"close-menu":function($event){return _vm.closeMenu(index)}}}):_vm._e(),(item.type == 1)?_c('Number',{attrs:{"info":item},on:{"success":function($event){return _vm.setFilterValue($event, index)},"close-menu":function($event){return _vm.closeMenu(index)}}}):_vm._e(),(item.type == 2)?_c('Date',{attrs:{"info":item},on:{"success":function($event){return _vm.setFilterValue($event, index)},"close-menu":function($event){return _vm.closeMenu(index)}}}):_vm._e(),(item.type == 3)?_c('Period',{attrs:{"info":item},on:{"success":function($event){return _vm.setFilterValue($event, index)},"close-menu":function($event){return _vm.closeMenu(index)}}}):_vm._e(),(item.type == 4)?_c('Radio',{attrs:{"info":item},on:{"success":function($event){return _vm.setFilterValue($event, index, item.type)},"close-menu":function($event){return _vm.closeMenu(index)}}}):_vm._e(),(item.type == 5)?_c('BooleanCheck',{attrs:{"info":item},on:{"success":function($event){return _vm.setFilterValue($event, index, item.type)},"close-menu":function($event){return _vm.closeMenu(index)}}}):_vm._e(),(item.type == 6)?_c('CurrencyValue',{attrs:{"info":item},on:{"success":function($event){return _vm.setFilterValue($event, index)},"close-menu":function($event){return _vm.closeMenu(index)}}}):_vm._e()],1)}),1),_c('v-menu',{attrs:{"offset-y":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"name":"filter","placeholder":"Pesquisar por","background-color":"transparent","append-icon":"mdi-magnify","autocomplete":"off","hide-details":"","solo":"","flat":""},on:{"keypress":function($event){return _vm.setMenuFilter()}},model:{value:(_vm.searchFor),callback:function ($$v) {_vm.searchFor=$$v},expression:"searchFor"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFilter),callback:function ($$v) {_vm.menuFilter=$$v},expression:"menuFilter"}},[_c('v-list',{staticClass:"accent lighten-1 py-0"},[_vm._l((_vm.dataFilters),function(item,index){return [(_vm.checkFilter(item))?_c('v-list-item',{key:index,on:{"click":function($event){return _vm.addFilter(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),(item.hint && _vm.searchFor)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.hint)+": "+_vm._s(_vm.searchFor)+" ")]):_vm._e()],1)],1):_vm._e()]})],2)],1),(_vm.selectedFilters.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"size":"22"},on:{"click":function($event){return _vm.clearFilters()}}},'v-icon',attrs,false),on),[_vm._v("mdi-backspace-outline ")])]}}],null,false,2893651600)},[_c('span',[_vm._v("Clear All")])]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }